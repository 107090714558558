<template>
    <div id="app">
      <Header />
      <template>
        <div class="table-container">
          <div class="total-summary">
            <p class="total-text">E-Voucher : <span class="total-value">{{ numberFormat(value_remain, 2) }}</span></p>
          </div>
          <table class="data-table">
            <thead>
              <tr>
                <th>Post Date</th>
                <th>In Value</th>
                <th>Out Value</th>
                <th>Remarks</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in displayedItems" :key="index">
                <td>{{ getDate(item.post_date) }}</td>
                <td>{{ numberFormat(item.in_value, 2) }}</td>
                <td>{{ numberFormat(item.out_value, 2) }}</td>
                <td>{{ item.remarks }}</td>
                <td>{{ item.status_code }}</td>
              </tr>
            </tbody>
          </table>

          <button v-if="hasMoreData && !loading" @click="loadMore" class="load-more-btn">{{ $t('evoucher_history.load_more') }}</button>
          <p v-if="loading" class="loading-text">{{ $t('evoucher_history.loading_text') }}</p>
          <p v-if="!hasMoreData && !loading" class="no-more-data">{{ $t('evoucher_history.no_more_data') }}</p>
        </div>
      </template>
      <Bottom />
    </div>
  </template>
  
<script>
import { server } from "@/services/constants";
import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
import moment from "moment";
import api from "@/services/api_villa";
// import api_cc from "@/services/chatcenter";
  
export default {
    name: "EVoucherHistory",
    data() {
        return {
            displayedItems: [],
            currentPage: 1,
            pageSize: 10,
            totalItems: 0,
            loading: false,
            value_remain: 0
        };
    },
    components: {
        Header,
        Bottom
    },
    async mounted() {
        this.loadData();
    },
    methods: {
        getDate(d)
        {
            return moment(new Date(d)).format('YYYY-MM-DD')
        },
        numberFormat(value, decimal) {
            return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
        },
        async loadData() {
            this.loading = true;
            try {
                const result = await api.refreshUserInfo(this.phoneNo);
                if (!result) {
                    this.$store.dispatch("doLogout");
                }
                else {
                    const response = await api.getTransaction(this.userInfo?.tm_code ?? "", this.currentPage, this.pageSize);
                    const data = response.data;
                    if (data && data.length>0) {
                      this.totalItems = data[0].all_recs;
                      this.value_remain = data[0].value_remain;
                    } else {
                      this.totalItems = 0;
                      this.value_remain = 0;
                    }
                    this.displayedItems.push(...data);
                    this.currentPage++;
                }
            } catch (error) {
                console.error("เกิดข้อผิดพลาดในการโหลดข้อมูล:", error);
            } finally {
                this.loading = false;
            }
        },
        async loadMore() {
            await this.loadData();
        },
    },
    computed: {
        userInfo() {
            return JSON.parse(localStorage.getItem('userInfo') ?? {});
        },
        phoneNo() {
            return localStorage.getItem(server.TOKEN_KEY);
        },
        hasMoreData() {
            return this.displayedItems.length < this.totalItems;
        },
    }
}
</script>

<style scoped>
/* ตั้งค่าพื้นฐาน */
body {
  background-color: #e3f2fd; /* พื้นหลังสีฟ้าอ่อน */
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

/* กล่องตาราง */
.table-container {
  padding: 1rem;
  background-color: #ffffff; /* พื้นหลังสีขาว */
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* เงาของกล่อง */
  /* margin: 2rem auto; */
  max-width: 100%; /* ไม่ให้เกินหน้าจอ */
  overflow-x: auto; /* เพิ่มการเลื่อนเมื่อกว้างเกิน */
  box-sizing: border-box; /* รวม padding และ border ในความกว้าง */
}

/* ตาราง */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  min-width: 600px; /* กำหนดขนาดขั้นต่ำเพื่อให้หัวตารางดูไม่เล็กเกินไป */
}

/* หัวตาราง */
.data-table th {
  background-color: #0D47A1; /* สีน้ำเงินเข้ม */
  color: #ffffff; /* ตัวอักษรสีขาว */
  padding: 0.75rem;
  text-align: left;
  font-size: 1rem;
  /* text-transform: uppercase; ตัวอักษรพิมพ์ใหญ่ */
}

/* แถวข้อมูล */
.data-table td {
  padding: 0.75rem;
  border-bottom: 1px solid #bbdefb; /* เส้นแบ่งสีฟ้าอ่อน */
  color: #555555; /* ตัวอักษรสีเทาเข้ม */
}

/* สีแถวสลับ */
.data-table tr:nth-child(even) {
  background-color: #e3f2fd; /* แถวสีฟ้าอ่อน */
}

/* แถวที่ชี้เมาส์ */
.data-table tr:hover {
  background-color: #bbdefb; /* สีน้ำเงินอ่อน */
  transition: background-color 0.3s ease;
}

/* ปุ่มโหลดเพิ่มเติม */
.load-more-btn {
  display: block;
  margin: 1rem auto;
  padding: 0.75rem 1.5rem;
  background-color: #1976D2; /* สีน้ำเงินกลาง */
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.load-more-btn:hover {
  background-color: #1565C0; /* สีน้ำเงินเข้ม */
}

/* ข้อความกำลังโหลด */
.loading-text {
  text-align: center;
  color: #1976D2; /* สีน้ำเงินกลาง */
  font-size: 1.1rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* ข้อความไม่มีข้อมูลเพิ่มเติม */
.no-more-data {
  text-align: center;
  color: #999999; /* สีเทาอ่อน */
  font-size: 1rem;
  margin: 1rem 0;
}

/* กล่องข้อความยอดรวม */
.total-summary {
  margin-bottom: 0.5rem;
  /* padding: 0.1rem; */
  background-color: #e3f2fd; /* สีฟ้าอ่อน */
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

/* ข้อความยอดรวม */
.total-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0D47A1; /* สีน้ำเงินเข้ม */
}

/* ค่าในยอดรวม */
.total-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: #B71C1C; /* สีน้ำเงินกลาง */
}

/* Responsive */
@media (max-width: 768px) {
  .data-table th,
  .data-table td {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .load-more-btn {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}
</style>