<template>
  <div id="app">
    <!-- <div class="main_bg"></div> -->
    <Header />
    <!-- <div class="home_bg" :style="home_bg"></div> -->
    <div class="home_bg"></div>
    <v-layout justify-space-between>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-img 
        contain
        :src="require('@/assets/circle_stamp.png')"
        height="150"
        max-width="150">
        <div class="div_point">
          <!-- <div class="value_point">99,999</div> -->
          <div class="value_point"> {{ numberFormat(userInfo.mp_stamp ?? 0, 0) }}</div>
          <div class="label_point">{{ $t('home.stamp') }}</div>
        </div>
      </v-img>
      <v-spacer></v-spacer>
      <v-img 
        contain
        :src="require('@/assets/circle_point.png')"
        height="150"
        max-width="150">
        <div class="div_point">
          <!-- <div class="value_point">99,999</div> -->
          <div class="value_point"> {{ numberFormat(userInfo.mp_point ?? 0, 0) }}</div>
          <div class="label_point">{{ $t('home.point') }}</div>
        </div>
        </v-img>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-layout>

    <v-layout justify-center>
      <div class="mt-n1" style="height:40px;">
        <a href="tel:02-0551000" target="_self">
          <v-avatar width="40" height="40" class="mr-n3">
            <img
              contain
              :src="require('@/assets/telephone.png')"
              alt=""
              width="30"
              height="30">
          </v-avatar>
        </a>
        <a href="https://shop.villamarket.com" target="_blank">
          <v-avatar width="40" height="40" class="mr-n3">
            <img
              contain
              :src="require('@/assets/web.png')"
              alt=""
              width="30"
              height="30">
          </v-avatar>
        </a>
        <a href="https://www.facebook.com/villamarketofficial" target="_blank">
          <v-avatar width="40" height="40" class="mr-n3">
            <img
              contain
              :src="require('@/assets/facebook.png')"
              alt=""
              width="30"
              height="30">
          </v-avatar>
        </a>
        <a href="https://line.villamarketjp.com" target="_blank">
          <v-avatar width="40" height="40" class="mr-n3">
            <img
              contain
              :src="require('@/assets/line.png')"
              alt=""
              width="30"
              height="30">
          </v-avatar>
        </a>
        <a href="https://vt.tiktok.com/ZSJYSdE2r" target="_blank">
          <v-avatar width="40" height="40" class="mr-n3">
            <img
              contain
              :src="require('@/assets/tiktok.png')"
              alt=""
              width="30"
              height="30">
          </v-avatar>
        </a>
        <a href="https://youtube.com/c/VillaMarketOfficial" target="_blank">
          <v-avatar width="40" height="40" class="mr-n0">
            <img
              contain
              :src="require('@/assets/youtube.png')"
              alt=""
              width="30"
              height="30">
          </v-avatar>
        </a>
      </div>
    </v-layout>

    <div class="evoucher_bg" v-if="(eVoucher?.res_code ?? '') == '00'"></div>
    <v-layout justify-center v-if="(eVoucher?.res_code ?? '') == '00'">
      <v-spacer></v-spacer>
      <v-img 
        contain
        :src="require('@/assets/e_voucher_point.png')"
        height="80"
        max-width="350">
        <div class="div_evoucher">
          <!-- <div class="evoucher_point ml-4">999,999</div> -->
          <div class="evoucher_point ml-4"> {{ numberFormat(eVoucher?.balance ?? 0, 0) }}</div>
        </div>
      </v-img>
      <v-spacer></v-spacer>
      <!-- <div class="div_link_evoucher mt-5 mr-5"> -->
        <span class="link--text div_link_evoucher mt-14 mr-3" style="cursor: pointer;" @click="eVoucherHistoryClick">{{ $t('home.eVoucherHistory') }}</span>
      <!-- </div> -->

      <!-- <v-spacer></v-spacer> -->
      <!-- <div class="title_evoucher mr-n2 mt-1"> -->
      <!-- <v-row justify="center">
        <v-spacer></v-spacer>
        <div class="mt-5">E-Voucher : {{ eVoucher.balance ?? 0 }}</div>
        <v-spacer></v-spacer>
        <div class="mt-5 mr-5">
          <span class="link--text" style="cursor: pointer;" @click="eVoucherHistoryClick">{{ $t('home.eVoucherHistory') }}</span>
        </div>
      </v-row> -->
    <!-- </div> -->
    </v-layout>

    <div class="title_bg mr-n2 mt-1">{{ $t('home.title_rewards') }}</div>
    <div class="title_remain_point mr-n2 mt-0" v-if="userInfo.mp_expirepoint ?? 0 > 0" v-html="getRemainPointMsg()"></div>

    <template v-if="coupons != null && coupons.length>0">
      <v-sheet
        class="mx-auto"
        elevation="0"
        width="100%"
      >
      <!-- show-arrows -->
        <v-slide-group
          v-model="model"
          class="pa-0"
          active-class="primary"
          center-active
        >
          <v-slide-item
            v-for="(coupon, index) in coupons"
            :key="index"
            v-slot="{ active, toggle }"
            class="ma-0"
          >
            <v-card
              :color="active ? undefined : 'white lighten-1'"
              class="ma-0"
              height="170"
              width="170"
              @click="toggle"
              dark
              flat
              tile>
              <v-row
                class="fill-height ma-n2 pa-n2"
                align-center
                justify="center"
              >
              <v-scale-transition>
                <v-img v-if="coupon.ref_type=='02'"
                  contain
                  :src="coupon.ref_image"
                  height="150"
                  max-width="150">
                </v-img>

                <v-img v-else-if="coupon.no_coupon"
                  contain
                  :src="require(`@/assets/${coupon.ref_image}`)"
                  height="150"
                  max-width="150">
                </v-img>

                <v-img v-else
                  contain
                  :src="coupon.ref_image"
                  height="150"
                  max-width="150">
                  <div class="div_point ml-2">
                    <!-- <div class="value_coupon">99,999</div> -->
                    <div class="value_coupon"> {{ numberFormat(coupon.cal_give ?? 0, 0) }}</div>
                    <div class="label_coupon">{{ $t('home.expire_date') }}<br />{{ coupon.exp_date }}</div>
                  </div>
                </v-img>
              </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </template>
    <div class="mt-4" v-else></div>

    <template v-if="banners != null && banners.length>0">
      <v-card class="mt-n4"
        color="#0D47A1"
        dark
        flat
        tile>
        <v-window v-model="onboarding">
          <v-window-item
            v-for="(banner, index) in banners"
            :key="index"
          >
            <v-card
              color="transparent"
              height="100%"
            >
              <v-row
                class="fill-height ma-0 pa-0"
                justify="center">
                <a :href="banner.url_link" target="_blank">
                    <img
                      class="img_banners"
                      contain
                      :src="banner.image_link"
                      alt=""
                      width="100%"
                      height="100%"/>
                </a>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>

        <v-card-actions class="justify-space-between">
          <v-btn
            text
            @click="prev"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group
            v-model="onboarding"
            class="text-center"
            mandatory
          >
            <v-item
              v-for="n in length"
              :key="`btn-${n}`"
              v-slot="{ active, toggle }"
            >
              <v-btn
                :input-value="active"
                icon
                @click="toggle"
              >
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn
            text
            @click="next"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>

    <Bottom />
    <!-- <ShowOverlay 
      v-model="isShowOverlay">
    </ShowOverlay> -->
  </div>
</template>

<script>
import { server } from "@/services/constants";
import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
import api from "@/services/api_villa";
// import api_cc from "@/services/chatcenter";

export default {
  name: "Home",
  data() {
    return {
      length: 0,
      onboarding: 0,
      coupons: [],
      banners: [],
      eVoucher: {
        "res_code": "",
        "res_desc": "",
        "balance": 0
      },

      isShowOverlay: false,
      model: null,
      // home_bg: { backgroundImage: `url(${require('@/assets/home_bg.png')})` }
    };
  },
  components: {
    Header,
    Bottom
  },
  // async mounted() {
  //     try {
  //       this.isShowOverlay = true;
  //       let encryptedText = localStorage.getItem(server.USER_NAME);
  //       let username = this.$CryptoJS.AES.decrypt(encryptedText, server.TOKEN_PASSWORD).toString(this.CryptoJS.enc.Utf8);
  //       console.log('-- username --', username);
  //       this.userInfo = await api.getUserInfo(username.toString());
  //       localStorage.setItem('userInfo', this.userInfo);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     finally {
  //       setTimeout(() => {
  //         this.isShowOverlay = false;
  //       }, 1000);
  //     }
  // },
  async mounted() {
    try {
      this.isShowOverlay = true;

      window.setInterval(this.changeBanner, 3000);

      const result = await api.refreshUserInfo(this.phoneNo);
      if (!result){
        this.$store.dispatch("doLogout");
      }
      else {
        // this.coupons = await api.getCoupon("0618276222");
        this.coupons = await api.getCoupon(this.phoneNo);
        // this.coupons = await api.getCoupon('phone-id-69EC6052-DF4F-4A99-9FD1-63E8391CE26E08092022114422094902259177E131DA-A19D-4C4D-8ACF-609EDB748522');
        // if (this.coupons.length == 0) {
        //   for (let i = 0; i < 3; i++) {
        //     this.coupons.push({ 
        //         "no_coupon": true,
        //         "ref_image": "no_coupon_".concat(i+1).concat(".png")
        //       }
        //     );
        //   }
        // }

        this.eVoucher = await api.getBalance(this.userInfo?.tm_code ?? "");

        this.banners = await api.getBanner(this.phoneNo);
        // console.log('-- coupons --', this.coupons);
        // console.log('-- banners --', this.banners);
        this.length = this.banners.length;
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setTimeout(() => {
        this.isShowOverlay = false;
      }, 1000);
    }
  },
  methods: {
    eVoucherHistoryClick() {
      this.$router.push(`/evoucher_history`);
    },
    // let myVar = setInterval(myTimer, 1000);
    getRemainPointMsg() {
      return `You have ${this.numberFormat(this.userInfo.mp_expirepoint ?? 0, 0)} Vplus points expiring on ${this.userInfo.mp_expiredate ?? ""}<br/>please redeem before then!`;
    },
    changeBanner() {
      this.next();
    },
    next () {
      this.onboarding = this.onboarding + 1 === this.length ? 0 : this.onboarding + 1
    },
    prev () {
      this.onboarding = this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1
    },
    numberFormat(value, decimal) {
      return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
    },
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo') ?? {});
    },
    phoneNo() {
      return localStorage.getItem(server.TOKEN_KEY);
      // let encryptedText = localStorage.getItem(server.TOKEN_KEY);
      // return this.$CryptoJS.AES.decrypt(encryptedText, server.TOKEN_PASSWORD).toString(this.CryptoJS.enc.Utf8);
    }
  }
}

</script>

<style scoped>
/* .main_bg {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    color: #ffffff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    background-size:100% 100%;
    background-repeat: no-repeat;
    position: fixed;
} */
.home_bg {
  background-image: url("../assets/home_bg.png");
  width: 100%;
  height: 200px;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-size:100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  /* left:0;right:0;top:0;bottom:0; */
}
.title_bg {
    height: 40px;
    width: 100%;
    color: #ffffff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    font-size: 1.2em !important;
    text-align: center;
    position: relative;
    padding: 5px;
}
.evoucher_bg {
  background-image: url("../assets/e_voucher_bg.png");
  width: 100%;
  height: 80px;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-size:100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  /* left:0;right:0;top:0;bottom:0; */
}
.div_evoucher {
    width: 150px;
    height: 150px;
    text-align: center;
    /* position: fixed; */
}
.div_link_evoucher {
    width: 50px; /* 100%; */
    margin-left: 330px;
    text-align: right;
    /* color: #0D47A1; */
    position: absolute;

    background-color: #B71C1C; /* สีพื้นหลัง */
    color: #ffff; /* สีข้อความ */
    padding: 1px; /* ช่องว่างรอบข้อความ */
    border-radius: 50px; /* ทำให้เป็นวงรี */
    display: inline-block; /* ให้ขนาดพอดีกับข้อความ */
    text-align: center; /* จัดข้อความให้อยู่กลาง */
    font-size: 10px; /* ขนาดตัวอักษร */
}
.evoucher_point {
    width: 150px;
    text-align: center;
    margin-top: 35px;
    font-size: 1.8rem !important;
    font-weight: bold;
    position: absolute;
}
.title_remain_point {
    height: 40px;
    width: 100%;
    color: #ffffff;
    background: rgb(255, 0, 0);
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%); */
    font-size: 0.8em !important;
    text-align: center;
    position: relative;
    /* vertical-align: middle; */
    padding: 0px;
}
.div_point {
    width: 150px;
    height: 150px;
    text-align: center;
    /* position: fixed; */
}
.value_point {
    width: 150px;
    text-align: center;
    margin-top: 40px;
    font-size: 2.5rem !important;
    font-weight: bold;
    position: absolute;
}
.label_point {
    width: 150px;
    text-align: center;
    margin-top: 115px;
    font-size: 0.9rem !important;
    position: absolute;
}
.value_coupon {
    width: 150px;
    text-align: center;
    margin-top: 20px;
    font-size: 2.15rem !important;
    font-weight: bold;
    position: absolute;
    color: #0D47A1;
}
.label_coupon {
    width: 150px;
    text-align: center;
    margin-top: 60px;
    font-size: 0.65rem !important;
    position: absolute;
    color: #B71C1C;
}

.img_banners {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>